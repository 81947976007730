import React, { useState } from "react";
import Page from "../components/Page";
import { graphql } from "gatsby";
import BlogCard from "../components/Blog/BlogCard";

export default function Index({ data }) {
  const { posts } = data.blog;

  console.log(posts)

  return (
    <>
      <Page title={"Våra artiklar | Elavtalen"}>
        <section className="container px-5 py-6 mx-auto ">
          <br />
          <h1 className="font-bold text-4xl md:text-5xl max-w-xl text-gray-900 leading-tight mb-8">Våra artiklar</h1>
          <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            {posts && posts.map((post) => {
              return <BlogCard key={post.id} title={post.frontmatter.title} description={post.frontmatter.description} link={"/blogg/" + post.frontmatter.pathname + "/"} image={post.frontmatter.featuredImage} />
            })}
          </div>
        </section>
      </Page>
    </>
  );
}

export const pageQuery = graphql`
  query posts {
    blog: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      posts: nodes {
        frontmatter {
          date(fromNow: true)
          title
          pathname
          description
          featuredImage {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
        id
      }
    }
  }
`;
