import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"

export default function BlogCard({ image, link, title, description, date }) {

    return (
        <Link to={link} className="blogCard">
            <div class="max-w-lg mx-auto">
                <div class="bg-white shadow-md border border-gray-200 rounded-lg max-w-sm mb-5">
                    <a href="#">
                        <GatsbyImage image={image.childImageSharp.gatsbyImageData} className="blogCardImage" />
                    </a>
                    <div class="p-5">
                        <a href="#">
                            <h5 class="text-gray-900 font-bold text-2xl tracking-tight mb-2">{title}</h5>
                        </a>
                        <p class="font-normal text-gray-700 mb-3">{description?.length > 155 ? description.substring(0, 155) + "..." : description}</p>
                        <Link to={link} class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center" href="#">
                            Läs mer →
                        </Link>
                    </div>
                </div>
            </div>

        </Link>
    );
}
